/* Slider.css */

/* Slider container */
.slider-container {
  position: relative;
  width: calc(100% - 10px); /* Fixed width calculation */
  margin: 20px 5px;
  background-color: rgba(255, 255, 255, 0.9); /* Use RGBA for background opacity */
  border-radius: 5px;
  padding: 0px 10px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25); /* Add a drop shadow */
  display: flex;
  align-items: center;
}

/* Play/Pause button */
.playPauseButton {
  margin-right: 10px;
  cursor: pointer;
}

/* Speed control wrapper */
.speedControl {
  position: relative;
}

/* Speed selector styling */
.speedSelector {
  cursor: pointer;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0);
  font-size: 16px;
  color: #000;
  border: none;
  font-weight: 500;
  text-align: center; /* Center text */
  min-width: 50px; /* Ensure the selector is always wide enough */
  display: flex;
  justify-content: center; /* Center the text horizontally */
  align-items: center; /* Center the text vertically */
  position: relative;
  z-index: 10; /* Ensure the selector is above the dropdown */
}

/* Speed selector focus state */
.speedSelector:focus {
  background-color: #f0f0f0; /* Change background color on focus */
  outline: none; /* Remove default focus outline */
}

/* Speed options dropdown */
.speedOptions {
  display: none; /* Hidden by default */
  position: absolute;
  bottom: calc(100% + 5px); /* Position above the speed selector */
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25); /* Add a drop shadow */
  padding: 5px 0px;
  z-index: 9; /* Ensure the dropdown is below the selector */
  text-align: center; /* Center text in options */
}

/* Show the dropdown when active */
.speedOptions.show {
  display: block;
}

/* Dropdown item styling */
.speedOption {
  padding: 8px 5px;
  font-size: 14px;
  color: #000;
  cursor: pointer;
  display: flex;
  justify-content: center; /* Center the text horizontally */
  align-items: center; /* Center the text vertically */
}

/* Dropdown item hover state */
.speedOption:hover {
  background-color: #e0e0e0; /* Change background color of options on hover */
}

/* Slider styling */
.slider {
  flex: 1;
  height: 8px;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #dddddd;
  border-radius: 5px;
  margin-left: 10px;
  padding: 0;
  z-index: 2;
  opacity: 1 !important;
}

/* Slider thumb */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 45px;
  height: 20px;
  background: transparent; /* Make it transparent */
  cursor: pointer;
  position: relative;
  z-index: 3; /* Ensure thumb is above ticks and labels */
}

/* Slider thumb for Firefox */
.slider::-moz-range-thumb {
  width: 45px;
  height: 20px;
  background: transparent; /* Make it transparent */
  cursor: pointer;
  position: relative;
  z-index: 3; /* Ensure thumb is above ticks and labels */
}

/* Custom thumb positioning */
.custom-thumb {
  position: absolute;
  width: 45px;
  height: 20px;
  background: #555; /* Thumb color */
  border: 2px solid #fff; /* Thumb border */
  border-radius: 40px; /* Rounded corners */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  pointer-events: none; /* Prevent thumb from interfering with slider interaction */
  z-index: 5;
  transform: translateX(140%) translateY(-10%);
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25); /* Add a drop shadow */
}
