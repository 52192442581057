/* LoadingOverlay.css */
.overlayContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.5); /* Slight grey with reduced opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
  
  .box {
    background-color: white;
    padding: 40px; /* Padding around the text and spinner */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0px 20px 15px rgba(0, 0, 0, 0.5); /* Box shadow */
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
  }
  
  .text {
    color: black;
    font-size: 32px; /* Increased font size */
    margin-bottom: 10px; /* Space between text and spinner */
  }
  
  .spinner {
    margin-bottom: 20px; /* Space between text and spinner */
  }
  
  .subtext {
    color: black;
    font-size: 18px; /* Increased font size */
  }
  