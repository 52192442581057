/* Marker styling */
.circular-marker {
  border-radius: 50%;
  border: 0.5px solid black;
  /* width: 16px !important;
  height: 16px !important; */
  line-height: 14px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  opacity: 0.7;
  z-index: 100; /* Lower z-index to ensure non-highlighted markers are below */
  opacity: 0;
  pointer-events: none;
}

/* Additional styling for highlighted markers */
.circular-marker.highlighted {
  border: 2px solid black; /* Adjust border color for highlighted marker */
  opacity: 0.7;
  /* width: 20px !important;
  height: 20px !important; */
  line-height: 18px;
  font-size: 16px;
  z-index: 1000; /* Higher z-index to ensure highlighted marker is above all others */
}

/* Optional: Adjust Leaflet control button styling */
.leaflet-control-button {
  background: white;
  border: none;
  padding: 5px;
  cursor: pointer;
}

/* Optional: Customize other visual elements */
.fa-location-dot {
  font-size: 20px;
  color: black;
  text-shadow: 
  1px 1px 0px white, 
  -1px -1px 0px white, 
  1px -1px 0px white, 
  -1px 1px 0px white;
  
  
}

.fa-star {
  font-size: 20px;
  color: white;
  text-shadow: 
  1px 1px 0px black, 
  -1px -1px 0px black, 
  1px -1px 0px black, 
  -1px 1px 0px black;
}

/* MapTitleStyles.css */

.map-title-overlay {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 1000;
  width: 80%; /* Adjust the width as needed */
}


.map-title-text {
  margin: 0;
  text-align: center;
}


.popup-table-container {
  max-width: 300px; /* Adjust the maximum width as per your design */
  overflow: auto; /* Add scrollbar if content overflows */
}

/* CSS for the popup */
.target-county-popup, .analog-county-popup {
  max-width: 250px; /* Set maximum width for the popup */
  font-size: 14px; /* Adjust font size if needed */
  opacity: 0.2;
}


.fa-circle-info {
  color: white;
}






.custom-overlay-container {
  position: fixed; /* Fixed positioning */
  bottom: 10px; /* Adjust position */
  left: 10px; /* Adjust position */
  background-color: white;
  border: 1px solid black;
  padding: 10px;
  z-index: 1000; /* Ensure it's on top */
  width: 250px; /* Adjust width as needed */
}

.custom-overlay {
  font-size: 14px; /* Adjust font size */
}


summary {
  font-size: 14px; /* Adjust the size as needed */
  font-weight: bold; /* Optional: Make the text bold */
}

details > p {
  margin-bottom: 5px !important;
  margin-top: 0px !important;
}

details {
  margin-bottom: 5px
}

