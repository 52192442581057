/* src/App.css */
.app-container {
  height: 100vh;
  width: 100vw;
}

.sidebarContentContainter {
  padding-top: 5px;
  /* background-color: #f4f4f4; */
}

/* Make the tooltip take the width of the parent container */
.introjs-tooltip {
  width: 70vw !important;  /* Sets the width to 70% of the viewport width */
  max-width: 100vw !important; /* Ensures it won't exceed viewport width */
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 55vh;
}

/* Set a fixed height for the tooltip text and enable scrolling */
.introjs-tooltip .introjs-tooltiptext {
  max-height: 50vh; /* Restricts height to 65% of the viewport height */
  overflow-y: auto; /* Enables vertical scrolling for overflow */
  padding: 0 10vw; /* Left and right padding */
}


/* Optional: Adjust the tooltip arrow position */
.introjs-tooltip .introjs-tooltiparrow {
  width: 15px; /* Adjust the arrow width */
  height: 15px; /* Adjust the arrow height */
}

.introjs-overlay {
   pointer-events: none; 
} 

.introjs-helperLayer { 
  pointer-events: none; 
}

.fa-arrow-pointer {
  color: black;
  font-size: 18px;
}

.fa-location-dot {
  font-size: 20px;
  color: black;
  text-shadow: 
  1px 1px 0px white, 
  -1px -1px 0px white, 
  1px -1px 0px white, 
  -1px 1px 0px white;
  
  
}

.fa-star {
  font-size: 20px;
  color: white;
  text-shadow: 
  1px 1px 0px black, 
  -1px -1px 0px black, 
  1px -1px 0px black, 
  -1px 1px 0px black;
}

.sidebar {
background-color:  white;
overflow-x: hidden;
width: 0;
height: 100%;
position: fixed;
left: 0;
z-index: 1;
box-shadow: 3px 0px 3px rgb(0, 0, 0, 0.25);
}

/* Add this CSS to your App.css */
.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 325px; /* Match the width of the sidebar */
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
  z-index: 2000; /* Ensure it's above the sidebar but below the main overlay */
  pointer-events: none; /* Prevent interactions with the sidebar */
}

/* .sidebar, .sidebar > * {
  transition: width 0.3s ease;
} */

.sidebar.visible {
width: 325px; /* Adjust width as needed */
z-index: 3000;
}

.menu-content {
padding: 20px;
}

.menu-toggle {
position: fixed;
left: 10px;
top: 10px;
z-index: 3000;
}

.map-container {
margin: 5px 10px;
float: right;
box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25); /* Add a drop shadow */
}

/* Add this CSS to your styles */
.leaflet-popup-content table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .leaflet-popup-content th, .leaflet-popup-content td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .leaflet-popup-content th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  /* Change icon color */
.fa-cloud-rain {
    color: rgb(30, 139, 255);
    font-size: 18px;
  }

   /* Change icon color */
.fa-thermometer-half {
    color: red;
    font-size: 18px;
  }

  .fa-triangle-exclamation {
    color: red;
    font-size: 18px;
    margin: 10px;
  }

  .menuSection {
    background-color: #ffffff;
    padding: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    /* margin-right: 5px;
    margin-left: 5px; */
    box-shadow: 2px 5px 10px rgb(0, 0, 0, 0.10);
    border-radius: 3px;
  }

  .menuOption {
    padding-left: 10px;
  }

  .menuSection p {
    font-weight: bold;
    font-size: 20px;
  }

  input[type="radio"] {
    margin-right: 10px;
  }

  .radioLabel{
    font-weight: 500;
  }

.TimeScaleOption {
  margin-left: 10px;
}

.selectPrompt {
  color: #8f8f8f;
}

.error-message {
  color: red;
  font-weight: 500;
}

.fa-angle-left {
  color: black;
  font-size: 30px;
  z-index: 1000;
  position: absolute;
  transform: translateX(325px);
  background-color: white;
  box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.25); /* Add a drop shadow */
  padding: 13px 2px;
  cursor: pointer;
}

.fa-angle-right {
  color: black;
  font-size: 30px;
  z-index: 1000;
  position: absolute;
  transform: translateX(2px);
  background-color: white;
  box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.25); /* Add a drop shadow */
  padding: 13px 2px;
  cursor: pointer;
}